import { apiInstance } from "services/api.service";
import { paramsBuildHelper } from "utils/common";

const urlPrefix = "/api/admin";

const baseCompanyRoute = `${urlPrefix}/company`;
export const getAllCompanies = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseCompanyRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertCompany = async (payload) => {
  return apiInstance.post(`${baseCompanyRoute}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editCompanyById = async ({ id, payload }) => {
  return apiInstance.put(`${baseCompanyRoute}/${id}`, payload);
};

export const deleteCompanyById = async ({ id }) => {
  return apiInstance.delete(`${baseCompanyRoute}/${id}`);
};

//storage api
const storageRoute = "/api/storage";

export const getSignedUrl = async (key) => {
  return apiInstance.get(`${storageRoute}?key=${key}`);
};

const baseNewsletterRoute = `${urlPrefix}/newsletter`;
export const getAllNewsletters = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseNewsletterRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertNewsletter = async (payload) => {
  return apiInstance.post(`${baseNewsletterRoute}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editNewsletterById = async ({ id, payload }) => {
  return apiInstance.put(`${baseNewsletterRoute}/${id}`, payload);
};

export const deleteNewsletterById = async ({ id }) => {
  return apiInstance.delete(`${baseNewsletterRoute}/${id}`);
};

const baseStayUpdatedRoute = `${urlPrefix}/stay-updated`;
export const getAllStayUpdated = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseStayUpdatedRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertStayUpdated = async (payload) => {
  return apiInstance.post(`${baseStayUpdatedRoute}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editStayUpdatedById = async ({ id, payload }) => {
  return apiInstance.put(`${baseStayUpdatedRoute}/${id}`, payload);
};

export const deleteStayUpdatedById = async ({ id }) => {
  return apiInstance.delete(`${baseStayUpdatedRoute}/${id}`);
};

const baseShareHolderRoutes = `${urlPrefix}/share-holders`;

export const getAllShareHolders = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseShareHolderRoutes}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertShareHolder = async (payload) => {
  return apiInstance.post(`${baseShareHolderRoutes}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateShareHolderById = async ({ id, payload }) => {
  return apiInstance.put(`${baseShareHolderRoutes}/${id}`, payload);
};

export const deleteShareHolderById = async ({ id }) => {
  return apiInstance.delete(`${baseShareHolderRoutes}/${id}`);
};

export const fetchAllCompanies = async () => {
  return apiInstance.get(`/api/company/all`);
};

export const fetchUserOptions = async () => {
  return apiInstance.get(`/api/admin/user/options`);
};

const baseUserRoute = `${urlPrefix}/user`;

export const getAllPaginatedUsers = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseUserRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

const baseUserWalletSellRoute = `${urlPrefix}/wallet/sell`;

export const getAllWalletSellData = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseUserWalletSellRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertWalletSell = async (payload) => {
  return apiInstance.post(`${baseUserWalletSellRoute}`, payload);
};

export const updateWalletSellById = async ({ id, payload }) => {
  return apiInstance.put(`${baseUserWalletSellRoute}/${id}`, payload);
};

export const deleteWalletSellById = async ({ id }) => {
  return apiInstance.delete(`${baseUserWalletSellRoute}/${id}`);
};

const baseUserWalletBuyRoute = `${urlPrefix}/wallet/buy`;

export const getAllWalletBuyData = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseUserWalletBuyRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertWalletBuy = async (payload) => {
  return apiInstance.post(`${baseUserWalletBuyRoute}`, payload);
};

export const getWalletBuyById = async ({ id }) => {
  return apiInstance.get(`${baseUserWalletBuyRoute}/${id}`);
};

export const updateWalletBuyById = async ({ id, payload }) => {
  return apiInstance.put(`${baseUserWalletBuyRoute}/${id}`, payload);
};

export const deleteWalletBuyById = async ({ id }) => {
  return apiInstance.delete(`${baseUserWalletBuyRoute}/${id}`);
};

const baseNDARoute = "/api/admin/nda";

export const getAllCompanyNDA = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseNDARoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const upsertNDA = async (payload) => {
  return apiInstance.post(`${baseNDARoute}`, payload);
};

export const deleteNDA = async ({ company_id, user_id }) => {
  return apiInstance.delete(baseNDARoute, {
    data: { company_id, user_id },
  });
};

const baseCompanyWalletRoute = `${urlPrefix}/company-wallet`;

export const getAllCompanyWalletData = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseCompanyWalletRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertCompanyWallet = async (payload) => {
  return apiInstance.post(`${baseCompanyWalletRoute}`, payload);
};

export const updateCompanyWalletById = async ({ id, payload }) => {
  return apiInstance.put(`${baseCompanyWalletRoute}/${id}`, payload);
};

export const deleteCompanyWalletById = async ({ id }) => {
  return apiInstance.delete(`${baseCompanyWalletRoute}/${id}`);
};

export const baseSurveyRoute = `${urlPrefix}/survey`;

export const getAllSurveys = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseSurveyRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const baseStocksRoute = `${urlPrefix}/stocks`;

export const getAllStocks = async ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
}) => {
  return apiInstance.get(
    `${baseStocksRoute}?${paramsBuildHelper({
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    })}`
  );
};

export const insertCompanyStock = async (payload) => {
  return apiInstance.post(`${baseStocksRoute}`, payload);
};

export const updateCompanyStockById = async ({ id, payload }) => {
  return apiInstance.put(`${baseStocksRoute}/${id}`, payload);
};

export const deleteStockById = async ({ id }) => {
  return apiInstance.delete(`${baseStocksRoute}/${id}`);
};

const statsRoute = `${urlPrefix}/stats`;

export const getAdminStats = async () => {
  return apiInstance.get(`${statsRoute}`);
};

const userRoutes = `${urlPrefix}/user`;

export const updateUser = async ({ id, payload }) => {
  return apiInstance.put(`${userRoutes}/${id}`, payload);
};

const notifcationRoute = `${urlPrefix}/notifications`;

export const getLatestNotifications = async () => {
  return apiInstance.get(`${notifcationRoute}/latest`);
};

export const markNotifcationAsRead = async(ids)=>{
  return apiInstance.put(`${notifcationRoute}/mark-read`,{
    notifications:ids
  });
}


export const listAllNotifications = async (cursor)=>{
  return apiInstance.get(`${notifcationRoute}?cursor=${cursor}`);
}