const { Avatar } = require("@mui/material");

function stringToColor(string) {   
    let hash = 0;   
    let i;    
    /* eslint-disable no-bitwise */   
    for (i = 0; i < string.length; i += 1) {     
      hash = string.charCodeAt(i) + ((hash << 5) - hash);   
    }    
    let color = "#";    
    for (i = 0; i < 3; i += 1) {     
      const value = (hash >> (i * 8)) & 0xff;     
      color += `00${value.toString(16)}`.slice(-2);   
    }    
    /* eslint-enable no-bitwise */    
    return color; 
  }  
  
  function stringAvatar(name) {
    if (!name) {
      name = 'Unknown';  // fallback name if input is not valid
    }
  
    const parts = name.split(' ');
    const initials = parts.length > 1
      ? `${parts[0][0]}${parts[1][0]}`
      : parts[0][0] || ''; 
  
    return {     
      sx: {       
        bgcolor: stringToColor(name),     
      },     
      children: initials,   
    };
  }  
  
  function UserAvatar({ user }) {   
    const userName = user?.fullname ?? user?.email ?? "Unknown User";   
    return <Avatar {...stringAvatar(userName)} />;
  }
  
export default UserAvatar;