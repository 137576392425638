import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import withPaginatedQuery from "components/HOC/withPaginatedQuery";
import { BaseDrawer, BaseModal } from "components/UI/Base";
import ZenDataGrid from "components/UI/ZenGrid";
import { CACHE_KEYS, MODAL_TYPE, MODEL_INITIAL_STATE } from "constants/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { adminApi } from "services/api";
import { useMutation } from "@tanstack/react-query";
import { downloadFile, showMutationError, sliceTextHelper } from "utils/common";
import queryClient from "config/queryClient";
import ThumbnailImage from "components/admin/UI/ThumbnailImage";
import DescriptionIcon from "@mui/icons-material/Description";
import DateChip from "components/UI/Date";
import ShareHolderEdit from "./ShareHolderEdit";
import ShareHolderAdd from "./ShareHolderAdd";
import { useGetSingedUrl } from "hooks/useGetSingedUrl";
import toast from "react-hot-toast";
import { useCompanyOptions } from "hooks/useApi";

const ManageShareHolders = ({
  paginationModel,
  setPaginationModel,
  data,
  isLoading,
  sortModel,
  handleSearch,
  search,
  setSortModel,
}) => {
  //local state
  const [modalState, setModalState] = useState(MODEL_INITIAL_STATE);
  const [selectedRow, setSelectedRow] = useState(null);

  //api calls
  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      adminApi.deleteShareHolderById({ id: selectedRow.article_id }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.SHARE_HOLDERS] }),
  });
  const [activePdfKey, setActivePdfKey] = useState(null);
  const { data: signedUrl, refetch } = useGetSingedUrl(activePdfKey);
  const { data: companyOptions } = useCompanyOptions();

  const deleteHandler = async () => {
    try {
      await mutateAsync();
      setModalState(MODEL_INITIAL_STATE);
      toast.success("Shareholder Record deleted successfully");
    } catch (error) {
      showMutationError(error);
    }
  };

  //event handlers
  const modalStateHandler = useCallback(
    (key, val) =>
      setModalState((prev) => ({
        ...prev,
        [key]: val,
      })),
    []
  );

  useEffect(() => {
    if (!activePdfKey) return;
    async function downloadPdf() {
      if (!activePdfKey) return;
      const toastId = toast.loading("Downloading File...");
      try {
        const result = await refetch();
        const url = signedUrl || result.data;
        if (!url) return;
        downloadFile(url);
      } catch (error) {
        showMutationError(error);
      } finally {
        toast.dismiss(toastId);
        setActivePdfKey(null);
      }
    }
    downloadPdf();
  }, [activePdfKey]);

  //get signed url

  //column definition
  const columns = useMemo(() => {
    return [
      {
        flex: 0.2,
        field: "article_id",
        headerName: "#",
        headerAlign: "center",
        align: "center",
      },
      {
        flex: 0.2,
        field: "featured_image",
        headerName: "Feature Image",
        sortable: false,
        renderCell: (params) => {
          return (
            <ThumbnailImage
              src={params.row.featured_image}
              alt={params.row?.title?.charAt(0)}
            />
          );
        },
      },
      {
        flex: 0.5,
        field: "title",
        headerName: "Title",
        valueGetter: (v) => sliceTextHelper(v, 50),
      },
      {
        flex: 0.5,
        field: "content",
        headerName: "Content",
        valueGetter: (v) => sliceTextHelper(v, 50),
      },
      {
        flex: 0.5,
        field: "company",
        headerName: "Company",
        valueGetter: (v) => sliceTextHelper(v?.company_name, 50),
      },
      {
        flex: 0.3,
        field: "file_name",
        headerName: "File Name",
        sortable: false,

        renderCell: (params) => {
          if (!params.row.file_name) return <></>;
          return (
            <Box>
              <IconButton
                onClick={() => {
                  setActivePdfKey(params.row.file_name);
                }}
              >
                <DescriptionIcon color={"error"} />
              </IconButton>
            </Box>
          );
        },
      },
      {
        flex: 0.3,
        field: "date_created",
        headerName: "Created At",
        renderCell: (params) => {
          if (!params.row.date_created) return <></>;
          return <DateChip label={params.row.date_created} />;
        },
      },
      {
        flex: 0.3,
        field: "date_modified",
        headerName: "Modified At",
        renderCell: (params) => {
          if (!params.row.date_modified) return <></>;
          return <DateChip label={params.row.date_modified} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        sortable: false,
        headerName: "Actions",
        getActions(params) {
          return [
            <IconButton
              key="edit-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.EDIT, true);
              }}
            >
              <Edit />
            </IconButton>,
            <IconButton
              key="delete-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.DELETE, true);
              }}
            >
              <Delete />
            </IconButton>,
          ];
        },
      },
    ];
  }, []);

  let content = <></>;
  if (isLoading) {
    content = (
      <Box my={4} textAlign="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data) {
    content = (
      <ZenDataGrid
        getRowId={(row) => row.article_id}
        data={data}
        columns={columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    );
  }
  return (
    <>
      <Card elevation={3} sx={{ maxWidth: "xl", borderRadius: 2 }}>
        <CardHeader
          title="Share Holder Updates"
          action={
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                onChange={handleSearch}
                sx={{ maxWidth: 200 }}
                size="small"
                variant="outlined"
                placeholder="Search"
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  modalStateHandler(MODAL_TYPE.CREATE, true);
                }}
              >
                + ShareHolder Update
              </Button>
            </Stack>
          }
        />
        <Box sx={{ height: 600 }}>{content}</Box>
      </Card>
      <>
        <BaseDrawer
          open={modalState[MODAL_TYPE.CREATE]}
          title={"Add ShareHolder Update"}
          toggleDrawer={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
        >
          <ShareHolderAdd
            companyOptions={companyOptions}
            handleClose={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
          />
        </BaseDrawer>
        {selectedRow ? (
          <>
            <BaseDrawer
              title={"Edit ShareHolder Update"}
              open={modalState[MODAL_TYPE.EDIT]}
              toggleDrawer={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
            >
              <ShareHolderEdit
                defaultValues={selectedRow}
                handleClose={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
                companyOptions={companyOptions}
              />
            </BaseDrawer>
            <BaseModal
              handleClick={deleteHandler}
              open={modalState[MODAL_TYPE.DELETE]}
              handleClose={() => modalStateHandler(MODAL_TYPE.DELETE, false)}
              loading={isPending}
              title="Are you sure you want to delete this ShareHolder Update?"
            />
          </>
        ) : null}
      </>
    </>
  );
};

export default withPaginatedQuery(ManageShareHolders, {
  queryKey: [CACHE_KEYS.SHARE_HOLDERS],
  queryFn: adminApi.getAllShareHolders,
});
