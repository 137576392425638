import React, { useState } from "react";
import UploadIcon from "../../../../../global/icons/UploadIcon";
import { Button } from "@mui/material";
import DoneIcon from "../../../../../global/icons/DoneIcon";
import DownloadLink from "../../../../../global/DownloadLink";
import { useAuth } from "../../../../../../hooks/useAuth";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../../../../../utils/zapier";
import { apiInstance } from "../../../../../../services/api.service";

function SigningSell({ companyId, activeStep, setActiveStep }) {
  //== URLs - START ==
  const userFilePath = `/api/deal/files?companyId=${companyId}&fileName=`;
  const urlSigningSpa = userFilePath + "spa.pdf";
  //== URLs - END ==

  //== FileUpload - START ==
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [selectedBankFile, setSelectedBankFile] = useState(null);
  const [uploadingBank, setUploadingBank] = useState(false);
  const [uploadedBank, setUploadedBank] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleBankFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedBankFile(file);
  };

  const handleBankDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedBankFile(file);
  };

  const handleFileUpload = async () => {
    if (selectedFile && selectedBankFile) {
      setUploading(true);
      setUploadingBank(true);

      const formData = new FormData();

      formData.append("companyId", companyId);
      formData.append("file", selectedFile);
      formData.append("fileName", selectedFile.name);
      formData.append("bankFile", selectedBankFile);
      formData.append("bankFileName", selectedBankFile.name);

      try {
        const response = await apiInstance.post(
          "/api/docs/user/upload",
          formData
        );

        if (!response.status >= 200 && response.status < 300) {
          throw new Error("File upload failed");
        }

        handleZapier(selectedFile.name);

        setUploaded(true);
        setUploadedBank(true);
        setActiveStep(activeStep + 1);
      } catch (error) {
        console.error("Error uploading file:", error);
        // Handle upload error
      } finally {
        setUploading(false);
        setUploadingBank(false);
      }
    }
  };

  // const handleAnotherFileClick = () => {
  //   setSelectedFile(null);
  //   setUploaded(false);
  //   setUploading(false);
  // };
  //== FileUpload - END ==

  //== Zapier - START ==
  const { user } = useAuth();

  const handleZapier = async (fileName) => {
    const leadContent = {
      userId: user.id,
      companyId: companyId,
      dashboardType: "sell",
      step: 2,
      fileName: fileName,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadTypeEnum.StocksSell, leadContent);
    await sendLeadToZapier(leadData);
  };
  //== Zapier - END ==

  return (
    <>
      <div>
        Upload a photo of your ID and bank account management certificate below”
      </div>
      <div className="separatorEmptyLarge"></div>
      <div className="separatorEmptyLarge"></div>

      <div>
        <DownloadLink text="Signing SPA | Deed" url={urlSigningSpa} />
      </div>

      <div className="separatorEmptyLarge"></div>
      <div className="separatorEmptyLarge"></div>

      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="dragBorder textAlignCenter"
      >
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
          <UploadIcon />
          <div>
            Upload Photo of your <strong>ID</strong>
          </div>
          <div className="fontGray">OR</div>
          <div className="orangeFont fontUnderline">Choose file</div>
        </label>
        {Boolean(selectedFile) && (
          <>
            <div className="containerFlex flexCenterHorizontally">
              <div>{uploaded ? <DoneIcon /> : <UploadIcon />}</div>
              <div className="separatorVerticalEmptySuperSmall"></div>
              <strong className="fontGray fontUnderline">
                {selectedFile.name}
              </strong>
            </div>
          </>
        )}
      </div>

      <div className="separatorEmptyLarge"></div>

      <div
        onDragOver={handleDragOver}
        onDrop={handleBankDrop}
        className="dragBorder textAlignCenter"
      >
        <input
          type="file"
          id="bankFileInput"
          onChange={handleBankFileChange}
          style={{ display: "none" }}
        />
        {!selectedBankFile ? (
          <label htmlFor="bankFileInput" style={{ cursor: "pointer" }}>
            <UploadIcon />
            <div>
              Upload a photo of your{" "}
              <strong>Bank Account Management Certificate</strong>
            </div>
            <div className="fontGray">OR</div>
            <div className="orangeFont fontUnderline">Choose file</div>
          </label>
        ) : (
          <>
            <div className="containerFlex flexCenterHorizontally">
              <div>{uploadedBank ? <DoneIcon /> : <UploadIcon />}</div>
              <div className="separatorVerticalEmptySuperSmall"></div>
              <strong className="fontGray fontUnderline">
                {selectedBankFile.name}
              </strong>
            </div>
          </>
        )}
      </div>

      <div className="separatorEmptyLarge"></div>

      <div className="containerFlex flexEnd">
        <Button
          variant="contained"
          color="orange"
          sx={{ textTransform: "none" }}
          style={{ borderRadius: 20 }}
          onClick={() =>
            handleFileUpload(
              selectedFile,
              selectedBankFile,
              setUploading,
              setUploaded
            )
          }
          disabled={
            !selectedFile || !selectedBankFile || uploading || uploadingBank
          }
        >
          {uploading || uploadingBank ? "Uploading..." : "Continue"}
        </Button>
      </div>

      {/* {!uploaded ? (
        <div className="containerFlex flexEnd">
          <Button
            variant="contained"
            color="orange"
            sx={{ textTransform: "none" }}
            style={{ borderRadius: 20 }}
            onClick={handleFileUpload}
            disabled={uploading || !selectedFile}
          >
            {uploading ? "Uploading..." : "Continue"}
          </Button>
        </div>
      ) : (
        <div
          className="containerFlex flexEnd linkDarkBlue"
          style={{ cursor: "pointer" }}
          onClick={handleAnotherFileClick}
        >
          <div className="fontUnderline">Upload Another File</div>
        </div>
      )} */}
    </>
  );
}

export default SigningSell;
