import { Stack, Typography } from "@mui/material";
import UserAvatar from "./UserAvatar";

import React from "react";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import {
  CACHE_KEYS,
  NOTIFICATION_ENTITY,
  NOTIFICATION_TYPE,
} from "constants/common";
import { useNavigate } from "react-router-dom";

const NotificationRender = ({ notification }) => {
  const navigate = useNavigate();
  const { mutateAsync } = useMutation({
    mutationFn: (ids) => adminApi.markNotifcationAsRead(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.NOTIFICATION],
      });
    },
  });

  const handleNavigate = (notification) => {
    let link = "/admin";

    switch (notification.entityModel) {
      case NOTIFICATION_ENTITY.UserWalletBuy:
        link += `/wallet/buy?viewId=${notification.entityId}`;
        break;

      default:
        break;
    }
    mutateAsync([notification.id]);
    navigate(link);
  };

  let content = "";
  switch (notification.type) {
    case NOTIFICATION_TYPE.CREATE_OFFER:
      content = `${
        notification.actor?.fullname ?? notification.actor?.email
      }  has created an offer with No. of stocks "${
        notification?.additionInfo?.number_of_stocks
      }" at Price per Stock: "${notification?.additionInfo?.price_per_stock}"`;
      break;
    case NOTIFICATION_TYPE.UPLOAD_SIGNED_DOCUMENT:
      content = `${
        notification.actor?.fullname ?? notification.actor?.email
      }  has  uploaded new document, check out`;
      break;

    default:
      break;
  }

  return (
    <Stack
      onClick={() => handleNavigate(notification)}
      sx={{
        borderBottom: "1px solid #f9fafb",
        padding: 1,
        cursor: "pointer",
        backgroundColor: notification?.isRead ? "#fff" : "#eff6ff",
      }}
      direction={"row"}
      alignItems="center"
      gap={1}
    >
      <UserAvatar user={notification.actor ?? {}} />
      <Typography variant="body1">{content}</Typography>
    </Stack>
  );
};

const NotificationList = ({ notifications }) => {
  return (
    <Stack gap={0.5}>
      {notifications.map((notification) => (
        <NotificationRender notification={notification} key={notification.id} />
      ))}
    </Stack>
  );
};

export default NotificationList;
