import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import NotificationList from "components/admin/Notification/NotificationList";
import ErrorMsg from "components/admin/UI/ErrorMessage";
import { CACHE_KEYS } from "constants/common";
import { adminApi } from "services/api";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import toast from "react-hot-toast";
import queryClient from "config/queryClient";

const NotificationsPage = () => {
  const {
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isError,
    data,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [CACHE_KEYS.NOTIFICATION, "INFINITE"],
    queryFn: async ({ pageParam = "" }) => {
      return adminApi
        .listAllNotifications(pageParam || "")
        .then((res) => res.data);
    },
    getNextPageParam: (lastPage) => {
      const lastCursor = lastPage?.metadata?.lastCursor;
      return lastCursor && lastPage.notifications.length
        ? lastCursor
        : undefined;
    },
    refetchOnWindowFocus: true,
  });

  const { mutateAsync } = useMutation({
    mutationFn: () => adminApi.markNotifcationAsRead([]),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.NOTIFICATION],
      });
      toast.success("Notifications marked as read");
    },
  });

  return (
    <Box sx={{ maxWidth: "lg", my: 4 }}>
      <Stack
        direction="row"
        mb={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography color={"primary"} variant="h6">
          All Notifications
        </Typography>
        <IconButton
          color="primary"
          disabled={isFetchingNextPage}
          onClick={() => mutateAsync()}
          title="Mark all as read"
        >
          <DoneAllIcon />
        </IconButton>
      </Stack>
      {isError ? (
        <ErrorMsg error={"Something went wrong , please try again"} />
      ) : null}
      {data?.pages?.map((page, index) => {
        return (
          <NotificationList
            notifications={page?.notifications || []}
            key={index}
          />
        );
      })}
      <Box sx={{ textAlign: "center", my: 2 }}>
        <Button
          variant="contained"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "Nothing more to load"}
        </Button>
      </Box>

      {isFetching || isFetchingNextPage ? (
        <Box
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : null}
    </Box>
  );
};

export default NotificationsPage;
