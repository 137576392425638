import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import ObjectView from "components/admin/details/ObjectView";
import DetailedImage from "components/admin/UI/DetailedImage";
import ErrorMsg from "components/admin/UI/ErrorMessage";
import Loader from "components/UI/Loader";
import { CACHE_KEYS } from "constants/common";
import { useGetSingedUrl } from "hooks/useGetSingedUrl";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { adminApi } from "services/api";
import { downloadFile, showMutationError } from "utils/common";
import DescriptionIcon from "@mui/icons-material/Description";

const ViewWalletBuy = ({ id }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [CACHE_KEYS.WALLET_BUY, id],
    queryFn: () => adminApi.getWalletBuyById({ id }),
  });

  const [activePdfKey, setActivePdfKey] = useState(null);
  const { data: signedUrl, refetch } = useGetSingedUrl(activePdfKey);

  useEffect(() => {
    if (!activePdfKey) return;
    async function downloadPdf() {
      if (!activePdfKey) return;
      const toastId = toast.loading("Downloading File...");
      try {
        const result = await refetch();
        const url = signedUrl || result.data;
        if (!url) return;
        downloadFile(url);
      } catch (error) {
        showMutationError(error);
      } finally {
        toast.dismiss(toastId);
        setActivePdfKey(null);
      }
    }
    downloadPdf();
  }, [activePdfKey]);

  if (isLoading) return <Loader height="300px" />;

  if (isError)
    return <ErrorMsg error={"Something went wrong , please try again"} />;

  const record = data?.data?.record ?? {};
  const uploadedFiles = data?.data?.uploadedDocs || [];

  return (
    <div>
      <ObjectView
        title={`#${record.id}`}
        data={{
          "No. of Stocks": record?.number_of_stocks,
          "Price Per Stock": record?.price_per_stock,
          "Progress Text": record?.progress_text ?? "-",
          Company: (
            <DetailedImage
              title={record?.company?.company_name}
              image={record?.company?.image_url}
            />
          ),
          User: (
            <DetailedImage
              title={record?.user?.fullname}
              subtitle={record?.user?.email}
            />
          ),
        }}
      />

      <Typography
        variant="h6"
        my={2}
        sx={{
          textTransform: "capitalize",
          color: "primary.main",
        }}
      >
        View Uploaded Documents
      </Typography>
      <Stack
        flexDirection={"row"}
        direction={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={2}
      >
        {uploadedFiles.map((file) => (
          <Box key={file.id}>
            <IconButton
              key={file.id}
              onClick={() => {
                setActivePdfKey(file.fileKey);
              }}
            >
              <DescriptionIcon sx={{ fontSize: "50px" }} color={"error"} />
            </IconButton>
            <Typography>
              {file.fileKey?.split("/").pop()?.slice(0, 10) + "..."}
            </Typography>
          </Box>
        ))}
      </Stack>
    </div>
  );
};

export default ViewWalletBuy;
