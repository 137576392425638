import React from "react";
import Header from "./Header";
import styles from "./TabContentA.module.css";
import Content from "./Content";
import Typography from "@mui/material/Typography";

function TabContentA({ tab = {}, header = "" }) {
  const { images = [], content = "" } = tab;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.firstDiv}>
          {/* Render Content only if content is valid */}
          {content ? (
            <Content content={content} />
          ) : (
            <Typography variant="body1">No content available.</Typography>
          )}
        </div>
        <div className={styles.secondDiv}>
          {Boolean(header) && <Header header={header} />}
          <div className="separatorEmptyLarge"></div>
        </div>
      </div>

      <div className={styles.imageContainer}>
        {images?.map((image, index) => (
          <React.Fragment key={index}>
            <div className={styles.box}>
              <img
                src={image}
                className={styles.image}
                alt={`${index}`}
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </div>
            <div className="separatorEmptyLarge"></div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default TabContentA;
