import React, { useState, useContext, useEffect } from "react";
import styles from "./Stocks.module.css";
import Tips from "./tips/Tips";
import ChangeButton from "./ChangeButton";
import Companies from "./companies/Companies";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { CompanyContext } from "../../../state/CompanyContext";
import BuyAndSellStocksDesktop from "../stocks/BuyAndSellStocksDesktop";
import BuyAndSellStocksMobile from "../stocks/BuyAndSellStocksMobile";
import { useAuth } from "../../../../hooks/useAuth";
import { apiInstance } from "../../../../services/api.service";

export default function Stocks() {
  const { user } = useAuth();
  const isMobile = useIsMobile();

  // == Is Buy Mode - START ==
  const [isBuyMode, setIsBuyMode] = useState(true);
  const [toggleClicked, setToggleClicked] = useState(false);

  const handleToggleMode = () => {
    setIsBuyMode(!isBuyMode);
    setToggleClicked(true);
  };

  // Set isBuyMode when the component is loaded for the first time
  useEffect(() => {
    if (user.userType === "seller") {
      setIsBuyMode(false);
    } else {
      setIsBuyMode(true);
    }
  }, [user.userType]);
  // == Is Buy Mode - END ==

  // == Current Company Id Stocks - START ==
  const {
    state: {
      currentCompanyIdStocks,
      companiesDataStocks,
      currentSellCompanyIdStocks,
    },
  } = useContext(CompanyContext);
  // const [company, setCompany] = useState(null);
  // == Current Company Id Stocks - END ==

  //== steps - START ==
  const [activeStep, setActiveStep] = useState(0);
  //== steps - END ==

  //== Companies - START ==
  // const companyIdsToDisplay = [7,8,10]; // 3 Companies
  const [companyDataUpdated, setCompanyDataUpdated] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [currentCompanyData, setCurrentCompanyData] = useState([]);
  console.log("currentCompanyData", currentCompanyData);

  useEffect(() => {
    if (companiesDataStocks.length > 0) {
      const currentCompany = currentCompanyStocksById(
        companiesDataStocks,
        currentCompanyIdStocks,
        currentSellCompanyIdStocks,
        isBuyMode
      );
      const company = currentCompany.company;
      setCurrentCompanyData(company);

      // This useEffect will be triggered when companyDataUpdated changes
      if (company && (companyDataUpdated || toggleClicked)) {
        // TODO: Replace isBuyMode with when handleToggleMode clicked
        setToggleClicked(false);
        const apiSlug = isBuyMode
          ? "/api/user-wallet/user-wallet-buy"
          : "/api/user-wallet/user-wallet-sell";
        apiInstance.get(apiSlug)
          .then((data) => {
            data = data.data;
            const userWalletCompanyData = data.userWalletData.find(
              (data) => data.company_id === company.id
            );

            if (
              userWalletCompanyData &&
              userWalletCompanyData.steps !== undefined
            ) {
              setCompanyData(userWalletCompanyData);
              let step = userWalletCompanyData.steps;
              setActiveStep(step);
              // TODO: ???
              // company.steps.stocks.range.defaultValue = userWalletCompanyData.number_of_stocks;
            } else {
              setCompanyData(null);
              setActiveStep(0);
            }
          })
          .catch((error) => {
            console.error("Error fetching user wallet data:", error);
          });
        setCompanyDataUpdated(false); // Reset the state after handling the update
      }
    }
  }, [
    companiesDataStocks,
    currentCompanyIdStocks,
    companyDataUpdated,
    companyData,
    activeStep,
    isBuyMode,
    toggleClicked,
    currentSellCompanyIdStocks,
  ]);
  //== Companies - START ==

  function currentCompanyStocksById(companies, id, sellId, isBuyMode) {
    const companyId = isBuyMode ? id : sellId;
    try {
      const currentCompany = companies.find(
        (company) => company.id === companyId
      );
      if (currentCompany) {
        return { company: currentCompany, error: null };
      } else {
        return { company: null, error: `Company with ID ${id} not found.` };
      }
    } catch (error) {
      return {
        company: null,
        error: "An error occurred while searching for the company.",
      };
    }
  }

  return (
    <>
      {currentCompanyData?.length !== 0 ? (
        <>
          <div className={`secondBackground ${styles.topShadow}`}>
            <ChangeButton
              isBuyMode={isBuyMode}
              handleToggleMode={handleToggleMode}
            />
            <div className={`maxWidth marginAuto ${styles.container}`}>
              {isMobile ? (
                // Mobile version
                <>
                  <div>
                    <div>
                      <Companies
                        type="stocks"
                        setCompanyDataUpdated={setCompanyDataUpdated}
                        isBuyMode={isBuyMode}
                      />
                    </div>
                    <div>
                      <BuyAndSellStocksMobile
                        isBuyMode={isBuyMode}
                        currentCompany={currentCompanyData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    </div>
                    <div className="separatorEmptyMedium"></div>
                    <Tips isBuyMode={isBuyMode} activeStep={activeStep} />
                    <div className="separatorEmptyLarge"></div>
                  </div>
                </>
              ) : (
                // Desktop version
                <>
                  <div className="separatorVerticalEmpty"></div>
                  <div>
                    <Companies
                      type="stocks"
                      setCompanyDataUpdated={setCompanyDataUpdated}
                      isBuyMode={isBuyMode}
                    />
                  </div>
                  <div className="separatorVerticalEmpty"></div>

                  <div>
                    {currentCompanyData?.id ? (
                      <div>
                        <BuyAndSellStocksDesktop
                          isBuyMode={isBuyMode}
                          currentCompany={currentCompanyData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      </div>
                    ) : (
                      <div className="companysNotFound">
                        Companies not found.
                      </div>
                    )}
                    <div className="separatorEmptyMedium"></div>
                    <Tips isBuyMode={isBuyMode} activeStep={activeStep} />
                    <div className="separatorEmptyLarge"></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
