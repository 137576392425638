import DetailedImage from "components/admin/UI/DetailedImage";
import HeaderRenderer from "components/admin/UI/InfoTooltip";
import { formatCurrency } from "utils/common";

export const rolePrefix = `${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`;
export const ADMIN_ROLE = "Admin";

export const CACHE_KEYS = {
  COMPANY: "COMPANY",
  NEWSLETTER: "NEWSLETTER",
  STAY_UPDATED: "STAY_UPDATED",
  SHARE_HOLDERS: "SHARE_HOLDERS",
  WALLET_SELL: "WALLET_SELL",
  WALLET_BUY: "WALLET_BUY",
  NDA: "NDA",
  COMPANY_WALLET: "COMPANY_WALLET",
  SURVEY: "SURVEY",
  STOCKS: "STOCKS",
  PAGINATED_USERS: "PAGINATED_USERS",
  NOTIFICATION: "NOTIFICATION",
};

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const MODAL_TYPE = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
  VIEW: "view",
};

export const MODEL_INITIAL_STATE = {
  [MODAL_TYPE.CREATE]: false,
  [MODAL_TYPE.EDIT]: false,
  [MODAL_TYPE.DELETE]: false,
};

export const VIEW_MODAL_INITIAL_STATE = {
  ...MODEL_INITIAL_STATE,
  [MODAL_TYPE.VIEW]: false,
};

export const COMPANY_THUMBNAIL_COLUMN = [
  {
    flex: 0.5,
    field: "company",
    headerName: "Company",
    sortable: false,
    renderCell: (params) => {
      const company = params.row.company;
      if (!company) return <></>;
      return (
        <DetailedImage
          image={company.image_url}
          title={company.company_name}
          subtitle={company.listing_preference}
        />
      );
    },
  },
];

export const USER_THUMBNAIL_COLUMN = [
  {
    flex: 0.5,
    minWidth: 300,
    field: "user",
    headerName: "User",
    sortable: false,
    renderCell: (params) => {
      const user = params.row.user;
      if (!user) return <></>;
      return <DetailedImage title={user.fullname} subtitle={user.email} />;
    },
  },
];

export const COMPANY_USER_THUMBNAIL_COLUMN = [
  ...USER_THUMBNAIL_COLUMN,
  ...COMPANY_THUMBNAIL_COLUMN,
];

export const COMMON_WALLET_COLUMNS = [
  {
    flex: 0.2,
    field: "id",
    headerName: "#",
    headerAlign: "center",
    align: "center",
  },
  ...COMPANY_USER_THUMBNAIL_COLUMN,
  {
    flex: 0.3,
    field: "number_of_stocks",
    headerName: "No. of Stocks",
    crmKey: "number_of_stocks",
    renderHeader: HeaderRenderer,
  },
  {
    flex: 0.3,
    field: "price_per_stock",
    headerName: "No. of Stocks",
    valueGetter: (v) => formatCurrency(v),
    crmKey: "price_per_stock",
    renderHeader: HeaderRenderer,
  },
  {
    flex: 0.3,
    field: "steps",
    headerName: "Steps",
    crmKey: "steps",
    renderHeader: HeaderRenderer,
  },
];

export const TITLE_CONTENT_DEFAULT_ENTRY = {
  title: "",
  content: "",
};

export const IMAGE_DEFAULT_ENTRY = {
  image: null,
};

export const TITLE_IMAGE_CONTENT_DEFAULT_ENTRY = {
  ...TITLE_CONTENT_DEFAULT_ENTRY,
  ...IMAGE_DEFAULT_ENTRY,
};

export const NOTIFICATION_TYPE = {
  CREATE_OFFER: "CREATE_OFFER",
  UPLOAD_SIGNED_DOCUMENT: "UPLOAD_SIGNED_DOCUMENT",
};

export const NOTIFICATION_ENTITY = {
  UserWalletBuy: "UserWalletBuy",
};
