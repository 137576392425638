import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabContentA from "./first/TabContentA";
import TabContentB from "./second/TabContentB";
import TabContentC from "./third/TabContentC";
import TabContentD from "./fourth/TabContentD";

function CompanyTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CompanyTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ currentCompany = {} }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { taba, tabb, tabc, tabd } = currentCompany?.tabs || {};

  const tabAContentIsEmpty =
    Array?.isArray(taba?.content) &&
    taba?.content?.every((item) =>
      Object?.values(item)?.every((value) => value === "")
    );
  const tabBContentIsEmpty =
    Array?.isArray(tabb?.content) &&
    tabb?.content?.every((item) =>
      Object?.values(item)?.every((value) => value === "")
    );
  const tabCContentIsEmpty =
    Array?.isArray(tabc?.content) &&
    tabc?.content?.every((item) =>
      Object?.values(item)?.every((value) => value === "")
    );
  const tabDContentIsEmpty =
    Array?.isArray(tabd?.content) &&
    tabd?.content?.every((item) =>
      Object?.values(item)?.every((value) => value === "")
    );

  const activeTabs = [
    !tabAContentIsEmpty &&
      taba && {
        title: taba?.title,
        component: (
          <TabContentA tab={taba ?? {}} header={currentCompany.header ?? {}} />
        ),
      },
    !tabBContentIsEmpty &&
      tabb && {
        title: tabb?.title,
        component: <TabContentB content={tabb.content ?? []} />,
      },
    !tabCContentIsEmpty &&
      tabc && {
        title: tabc?.title,
        component: <TabContentC tab={tabc ?? {}} />,
      },
    !tabDContentIsEmpty &&
      tabd && {
        title: tabd?.title,
        component: <TabContentD content={tabd.content ?? []} />,
      },
  ].filter(Boolean);

  return (
    <>
      {activeTabs?.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="Tabs">
              {activeTabs?.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.title || "-"}
                  {...a11yProps(index)}
                  sx={{ textTransform: "none" }}
                />
              ))}
            </Tabs>
          </Box>
          {activeTabs.map((tab, index) => (
            <CompanyTabPanel key={index} value={value} index={index}>
              {tab?.component}
            </CompanyTabPanel>
          ))}
        </Box>
      )}
    </>
  );
}
