import { Box } from "@mui/material";
import React from "react";
import LoadingDots from "../global/LoadingDots";

const Loader = ({height="100vh"}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <LoadingDots />
    </Box>
  );
};

export default Loader;
