import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
} from "@mui/material";
import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useQuery } from "@tanstack/react-query";
import { CACHE_KEYS } from "constants/common";
import ErrorMsg from "../UI/ErrorMessage";
import { Link, useNavigate } from "react-router-dom";
import NotificationList from "./NotificationList";
import { adminApi } from "services/api";
import LoadingDots from "components/global/LoadingDots";
import { useMemo } from "react";

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: [CACHE_KEYS.NOTIFICATION],
    queryFn: adminApi.getLatestNotifications,
    refetchIntervalInBackground: true,
    refetchInterval: 10_000, // 10s
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleNavigate = () => {
    navigate("/admin/notifications");
  };

  const unReadNotificationsCount = useMemo(() => {
    if (!data?.data) return 0;
    return data?.data?.filter((notification) => !notification.isRead).length ?? 0;
  }, [data]);

  //cotent rendering

  let content = <></>;
  if (isLoading) {
    content = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <LoadingDots />
      </Box>
    );
  }
  if (isError) {
    content = (
      <ErrorMsg error={"Fail to fetch notificatoins, try again later"} />
    );
  }

  if (data?.data) {
    content = (
      <>
        <NotificationList notifications={data.data ?? []} />
        <Button
         LinkComponent={Link}
         to="/admin/notifications"
          sx={{
            my: 1,
          }}
          fullWidth
          onClick={handleNavigate}
          color="primary"
          variant="text"
        >
          View All
        </Button>
      </>
    );
  }

  return (
    <>
      <IconButton onClick={handleClick} size="large" color="primary">
        <Badge badgeContent={unReadNotificationsCount ?? 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            width: "400px",
            minHeight: "400px",
            p: 1,
          }}
        >
          {content}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationMenu;
