import React from 'react';
import styles from './Header.module.css';

function Header(props) {
  const { header } = props;

  // Ensure `header` is defined and it's an object
  const items = Object.entries(header || {}).map(([key, value]) => ({
    title: value?.title || '',
    content: value?.content || '',
  }));

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          <div>{item.content}</div>
        </div>
      ))}
    </div>
  );
}

export default Header;
