import { AxiosError } from "axios";
import toast from "react-hot-toast";

const ALPHABETS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function SizeCalculation(fileSize) {
  if (Math.round(fileSize / 100) / 10 > 1000) {
    return `${(Math.round(fileSize / 100) / 10000).toFixed(1)} mb`;
  } else if (Math.round(fileSize / 100) / 10 < 1000) {
    return `${(Math.round(fileSize / 100) / 10).toFixed(1)} kb`;
  }
}
export function showMutationError(error) {
  let message = error;
  if (error instanceof Error) {
    message = error.message;
  }

  if (error instanceof AxiosError) {
    message = error.response?.data?.message;
  }

  toast.error(message?.slice(0, 100));
}

export const paramsBuildHelper = (queryParams) => {
  const params = new URLSearchParams();
  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key]) {
      params.append(key, queryParams[key]);
    }
  });
  return params?.toString();
};

export const downloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = ""; // Optional: specify a filename
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const updateFormDataHelper = (value, dirtyFields) => {
  const dirtyFieldsSet = new Set(Object.keys(dirtyFields));
  if (!dirtyFieldsSet.size) {
    throw new Error("No changes made");
  }
  const formData = new FormData();
  Object.keys(value).forEach((key) => {
    const $value = value[key];
    if (dirtyFieldsSet.has(key) && $value !== null) {
      formData.append(key, value[key]);
    }
  });
  return formData;
};

export const updateJsonFormHelper = (value, dirtyFields) => {
  const dirtyFieldsSet = new Set(Object.keys(dirtyFields));
  if (!dirtyFieldsSet.size) {
    throw new Error("No changes made");
  }
  const jsonData = {};
  Object.keys(value).forEach((key) => {
    const $value = value[key];
    if (dirtyFieldsSet.has(key) && $value !== null) {
      jsonData[key] = value[key];
    }
  });
  return jsonData;
};

export const sliceTextHelper = (text = "", length = 50) => {
  if (text.length > length) {
    return text.slice(0, length) + "...";
  }
  return text;
};

export function formatCurrency(amount, currency = "USD", locale = "en-US") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
}

export function generateDefaultFormsHelper(forms, defaultEntry) {
  if (forms?.length > 0) return forms;
  return [defaultEntry];
}

export function formatCompanyDataWalletHelper(payload) {
  return {
    company_id: payload.company_id,
    meta: {
      category: payload.category,
      companyLogo: payload.companyLogo,
      companyName: payload.companyName,
    },
    header: payload.header.reduce((prev, accumulator, index) => {
      prev[`item${ALPHABETS[index]}`] = {
        title: accumulator.title,
        content: accumulator.content,
      };
      return prev;
    }, {}),
    main: payload.main_paragraphs.reduce((prev, accumulator, index) => {
      prev[`paragraph${ALPHABETS[index]}`] = {
        title: accumulator.title,
        content: accumulator.content,
      };
      return prev;
    }, {}),
  };
}

export function validateImage(
  file,
  maxSizeMB = 5,
  allowedTypes = ["image/jpeg", "image/png"]
) {
  return new Promise((resolve, reject) => {
    // Check if file type is valid
    if (!allowedTypes.includes(file.type)) {
      reject("Invalid file type. Allowed types are: JPEG, PNG.");
      return;
    }

    // Check file size
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      reject(`File size exceeds the limit of ${maxSizeMB} MB.`);
      return;
    }

    resolve(window.URL.createObjectURL(file));
  });
}

export function isImageUrl(value) {
  if (!(typeof value === "string")) return false;
  return value.startsWith("http://") || value.startsWith("https://");
}

export const formatCompanyStocksDataHelper = (payload) => {
  return {
    company_id: payload.company_id,
    meta: payload.meta,
    steps: {
      stocks: {
        price: payload.price,
        range: payload.range,
      },
    },
    header: {
      items: payload.header,
    },
    taba: {
      title: payload.taba_title,
      content: payload.taba_content,
    },
    tabb: {
      title: payload.tabb_title,
      content: payload.tabb_content.map((item) => ({
        title: item.title,
        content: item.content,
      })),
    },
    tabc: {
      title: payload.tabc_title,
      content: payload.tabc_content,
    },
    tabd: {
      title: payload.tabd_title,
      content: payload.tabd_content,
    },
  };
};

const imagesAppendixHelper = ({ images, formData, baseKey }) => {
  images.forEach((image, index) => {
    formData.append(`${baseKey}[${index}]`, image);
  });
};

export const formatCompanyStocksImagesHelper = ({ payload, formData }) => {
  //handle tab a images
  imagesAppendixHelper({
    images: payload.taba_images.map((item) => item.image),
    formData,
    baseKey: "taba_image",
  });
  //handle tab b images
  imagesAppendixHelper({
    images: payload.tabb_content.map((item) => item.image),
    formData,
    baseKey: "tabb_image",
  });
  //handle tab c images
  imagesAppendixHelper({
    images: payload.tabc_images.map((item) => item.image),
    formData,
    baseKey: "tabc_image",
  });
};

export function generateUniqueFilename(originalFilename) {
  // Extract the file name and extension
  const dotIndex = originalFilename.lastIndexOf(".");
  const name = originalFilename.slice(0, dotIndex);
  const extension = originalFilename.slice(dotIndex);

  // Generate a random string using crypto
  const randomString = crypto.getRandomValues(new Uint32Array(1))[0].toString(36);

  // Construct and return the new filename
  return `${name}_${randomString}${extension}`;
}
